<template>
    <div class="program-page">
        <div class="program-header">
            <h1 class="program-title pointer" @click="goTo">{{ $t('layout.sysName') }}</h1>
            <div class="name-wrap pointer" @click="showElement($event, undefined, 18)">
                <div class="name line-overflow">
                    {{ formValidate.name }}
                </div>
                <Icon class="icon" type="md-create" />
            </div>
            <Button class="float-right" type="primary" :disabled="loadingSaveAs" :loading="loadingSave" @click="handleSave">{{ $t('common.save') }}</Button>
            <Button v-if="status==='edit'" class="float-right margin-right" type="primary" :disabled="loadingSave" :loading="loadingSaveAs" @click="handleSaveAs">{{ $t('common.saveAs') }}</Button>
        </div>
        <div class="editor-body">
            <div :class="['side-bar', hidden ? 'side-bar-hidden' : '']">
                <div class="side-bar-item side-left-item">
                    <div class="block pointer" @click="handleHidden">
                        <div class="img-wrap">
                            <img class="image-center" src="~assets/template.png">
                        </div>
                        <span>{{ $t('pages.program.layout') }}</span>
                    </div>
                </div>
                <div class="side-bar-item side-right-item">
                    <div class="cells-title">{{ $t('pages.program.layout') }}</div>
                    <RadioGroup v-model="formValidate.orientation" type="button" @on-change="handle_orientation">
                        <Radio v-for="(item, key) in layouts" :label="key" :key="key" :disabled="status==='edit'&&formValidate.orientation!==key" >{{ item.text[lang]?item.text[lang]:item.text._ }}</Radio>
                        }
                    </RadioGroup>
                    <div class="cells-info" v-for="(item, index) in layout_combinations" :key="index">
                        <h3 class="cells-title">
                            {{ item.text[lang]?item.text[lang]:item.text._ }}
                            <Icon type="ios-information-circle-outline" />
                        </h3>
                        <div v-for="(subitem, subindex) in item.layouts"
                            class="cells-item"
                            :key="`${index}-${subindex}`"
                            @click="handle_layout(index,subindex, item.key, subitem.key)">
                            <div class="cells-wrapper pointer">
                                <div
                                    class="cells-con"
                                    :style="{ position: 'relative', width: layouts[formValidate.orientation].width*scale2 + 'px', height: layouts[formValidate.orientation].height*scale2 + 'px' }">
                                    <div
                                        v-for="(cell, cellindex) in subitem.cells"
                                        :key="cellindex"
                                        :style="{ position: 'absolute', top: cell.y*scale2 + 'px', left: cell.x*scale2 + 'px', width: cell.width*scale2 + 'px', height: cell.height*scale2 + 'px', border: '1px solid #eef2f8' }"></div>
                                </div>
                            </div>
                            <span class="text">{{  subitem.text[lang]?subitem.text[lang]:subitem.text._ }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="['editor', hidden ? 'editor-hidden' : '']" >
                <div
                    v-if="formValidate.orientation"
                    class="editor-container-wrap"
                    :style="{ paddingTop: `calc(50vh - 32.5px - ${layouts[formValidate.orientation].height*scale/2}px)` }"
                    @click="handleBackground">
                    <div
                        id="canvas"
                        class="editor-container"
                        :style="{ width: layouts[formValidate.orientation].width*scale + 'px', height: layouts[formValidate.orientation].height*scale + 'px' }">
                        <div
                            v-for="(item, index) in layout_cells"
                            :key="index"
                            class="cells pointer"
                            :style="{ top: item.y*scale + 'px', left: item.x*scale + 'px', width: item.width*scale + 'px', height: item.height*scale + 'px' }"
                            @click.stop="handleContentList(index)">
                            <Icon class="image-center add-icon" type="ios-add" size="46" />
                            <template v-if="item.materials && item.materials.length !== 0">
                                <img
                                    v-if="allowList.indexOf(item.materials[item.conIndex].media_type) > -1 || item.materials[item.conIndex].material_type && allowList.indexOf(item.materials[item.conIndex].material_type.material_type) > -1"
                                    v-drag="greet"
                                    :data-index="index"
                                    :key="index"
                                    ondragstart="return false;"
                                    :style="{ position: 'absolute', top: `${item.materials[item.conIndex].y * scale}px`, left: `${item.materials[item.conIndex].x * scale}px`, width: `${item.materials[item.conIndex].width * scale}px`, height: `${item.materials[item.conIndex].height * scale}px`, cursor: 'move' }"
                                    :src="`/cym/${item.materials[item.conIndex].uri}/preview`" @mousewheel="handleMousewheel($event,  item)" @DOMMouseScroll="handleMousewheel($event,  item)">
                                <img
                                    v-else
                                    class="image-center"
                                    key="index"
                                    ondragstart="return false;"
                                    :src="`/cym/${item.materials[item.conIndex].uri}/thumbnail?size=[${item.width*scale},-1]`">
                            </template>
                            <span class="module-info">{{ $t('pages.program.module') }}{{ index+1 }}：{{ item.width }}&nbsp;X&nbsp;{{ item.height }}</span>
                        </div>
                    </div>
                    <div class="model-text">{{ this.model_text }}</div>
                </div>
                <div class="controls">
                    <span>{{ percent }}%</span>
                    <ButtonGroup>
                        <Button icon="md-add" @click="add"></Button>
                        <Button icon="md-remove" @click="minus"></Button>
                    </ButtonGroup>
                </div>
            </div>
            <div v-if="showContentList" class="editor-panel-wrap editor-content-block">
                <!-- 内容列表 -->
                <div class="content-setting">
                    <h2 class="content-title">{{ $t('pages.program.contentList') }}</h2>
                    <draggable v-model="apply.canvas.cells[cellIndex].materials" forceFallback="true" group="people" animation="800" @start="drag = true" @end="drag = false">
                        <transition-group>
                            <div class="content-wrap content-wrap-move" v-for="(item,index) in apply.canvas.cells[cellIndex].materials" :key="index" @click="handleConIndex(index)">
                                <div class="img-wrap">
                                    <img class="image-center" :src="`/cym/${item.uri}/thumbnail`">
                                    <div class="name line-overflow">{{ item.name }}</div>
                                    <!-- <Icon class="icon-remove pointer" type="ios-trash-outline" size="20" @click.native="handleRemoveContent(index)" /> -->
                                    <div class="icon-replace-wrap pointer" @click.stop="handleReplaceContent(index)">
                                        <Icon type="md-repeat" class="icon" size="20" />
                                    </div>
                                    <div class="icon-remove-wrap pointer" @click.stop="handleRemoveContent(index)">
                                        <i class="iconfont icon-lajitong icon"></i>
                                    </div>
                                </div>
                                <div class="info-wrap">
                                    <div class="times" v-if="item.media_type === 'video' || (item.play_settings && item.play_settings[0] === 'times')">
                                        <p class="text">{{ $t('pages.program.playCount') }}</p>
                                        <InputNumber class="input" size="small" :max="10" :min="1" v-model="item.times"></InputNumber>
                                        <span>{{ $t('pages.program.times') }}</span>
                                    </div>
                                    <div class="duration" v-else>
                                        <p class="text">{{ $t('pages.program.playDuration') }}</p>
                                        <InputNumber class="input" size="small" :max="60" :min="1" v-model="item.duration"></InputNumber>
                                        <span>{{ $t('pages.program.seconds') }}</span>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </draggable>
                    <div class="content-wrap content-add pointer" @click="handleContent">
                        <Icon class="image-center" type="md-add" />
                    </div>
                </div>
            </div>
            <div v-else class="editor-panel-wrap">
                <!-- 背景音乐 -->
                <div class="background-setting">
                    <h2 class="background-title">{{ $t('pages.program.backgroundSetting') }}</h2>
                    <div class="edit-size-wrap">
                        <div class="size-action-row">
                            <span>{{ $t('pages.program.size') }}</span>
                            <span class="pointer" @click="handleResizeProgram">{{ $t('common.edit') }}</span>
                        </div>
                        <div class="size-display-row">
                            <div class="size-width">{{ formValidate.orientation?layouts[formValidate.orientation].width:'' }}px</div>
                            <div class="size-height">{{ formValidate.orientation?layouts[formValidate.orientation].height:'' }}px</div>
                        </div>
                    </div>
                    <span class="audio-type">{{ $t('pages.program.backgroundMusic') }}</span>
                    <div class="audio-wrap pointer" @click="handleAudio">
                        <Icon class="image-center" type="md-add" />
                        <div class="img-wrap" v-if="audio">
                            <img class="image-center" :src="`/cym/${audio.uri}/thumbnail`">
                            <div class="name line-overflow" v-if="audio">{{ audio.name }}</div>
                        </div>
                        <!-- <Icon class="icon-remove" type="ios-trash-outline" size="20" @click.stop="handleRemoveAudio" v-if="audio" /> -->
                        <i class="iconfont icon-lajitong icon-remove pointer" @click.stop="handleRemoveAudio" v-if="audio"></i>
                    </div>
                    <template v-if="!local_install">
                        <span class="audio-type">{{ $t('pages.program.voicebroadcast') }}</span>
                        <div class="tts-wrap pointer" @click="handleTTS">
                            <Icon class="image-center" type="md-add" />
                            <div class="img-wrap" v-if="ttsInfo">
                                <img class="image-center" src="~assets/audio.png">
                                <div class="name line-overflow" >{{ ttsInfo.text }}</div>
                            </div>
                            <i class="iconfont icon-lajitong icon-remove pointer" @click.stop="handleRemoveTTS" v-if="ttsInfo"></i>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <audio-modal
            :show="audioModalShow"
            :selectedAudio="audio"
            :is_org="is_org"
            @confirm="handleAudioConfirm"
            @cancel="handleAudioCancel"></audio-modal>
        <content-modal
            :show="contentModalShow"
            :cellWidth="cellWidth"
            :cellHeight="cellHeight"
            :is_org="is_org"
            :lang="lang"
            @confirm="handleContentConfirm"
            @cancel="handleContentCancel"></content-modal>
        <tts-modal
            :show="ttsModalShow"
            :ttsInfo="ttsInfo"
            :lang="lang"
            @cancel="handleTtsCancel"
            @confirm="handleTtsConfirm"></tts-modal>
    </div>  
</template>
<script>
    import audioModal from './components/audioModal'
    import contentModal from './components/contentModal'
    import ttsModal from './components/ttsModal'
    import { rename } from '@/mixins/index'
    import draggable from 'vuedraggable'
    import html2canvas from "html2canvas"
    import util from '@/libs/util'
    import { ResizeProgram } from 'components/preview.js'

    export default {
        name: "",
        mixins: [rename],
        components: {
            audioModal,
            contentModal,
            ttsModal,
            draggable
        },
        props: [],
        directives:{
            drag:{
                bind(el, binding) {
                    let op = el;  //当前元素
                    op.onmousedown = function (e) {
                        //鼠标按下，计算当前元素距离可视区的距离
                        let disX = e.clientX - op.offsetLeft;
                        let disY = e.clientY - op.offsetTop;
                        let x = e.clientX;
                        let y = e.clientY;
                        let width = op.offsetWidth,
                            height = op.offsetHeight,
                            cell_width = op.parentElement.offsetWidth,
                            cell_height = op.parentElement.offsetHeight;
                        // binding.value({ disX: disX, disY: disY})
                        document.onmousemove = function (e) {
                            //通过事件委托，计算移动的距离
                            let l = e.clientX - disX;
                            let t = e.clientY - disY;
                            //移动当前元素
                            op.style.left = l + 'px';
                            op.style.top = t + 'px';
                        };
                        document.onmouseup = function (e) {
                            //通过事件委托，计算移动的距离
                            let l = e.clientX - disX;
                            let t = e.clientY - disY;
                            //移动当前元素
                            if (l + width <= 0 || l >= cell_width || t + height <= 0 || t >= cell_height) {
                                l = cell_width / 2 - width / 2
                                t = cell_height / 2 - height / 2
                            }

                            op.style.left = l + 'px';
                            op.style.top = t + 'px';
                            let diff_x = e.clientX - x;
                            let diff_y =  e.clientY - y;
                            //将此时的位置传出去
                            binding.value({ diff_x: diff_x, diff_y: diff_y, index: Number(op.dataset.index) })
                            document.onmousemove = null;
                            document.onmouseup = null;
                        };
                    };
                }
            }
        },
        data () {
            return {
                orientation: 'horizontal',
                formValidate: {
                    name: '',
                    orientation: '',
                    key1: '',
                    key2: ''
                },
                // ruleValidate: {
                //     name: [
                //         { required: true, message: '请输入节目名称', trigger: 'blur' }
                //     ]
                // },
                layout_combinations: [],
                layouts: {},
                layout_cells: [],
                layout_layouts: [],
                percent: 30,
                scale: 0.3,
                audioModalShow: false,
                audio: null,
                showContentList: false,
                value1: 0,
                contentModalShow: false,
                cellIndex: null,
                apply: {
                    mode: '',
                    name: '',
                    canvas: {
                        cells: []
                    },
                    background: {
                        materials: []
                    }
                },
                status: 'add',
                rowid: null,
                scale2: 0.04,
                hidden:  false,
                imgBlobData: null,
                cellWidth: null,
                cellHeight: null,
                loadingSave: false,
                loadingSaveAs: false,
                replace: false,
                replaceIndex: null,
                allowList: ['image'],
                offset: 10,
                ttsModalShow: false,
                ttsInfo: null,
                model: '24_32_43_55inches',
                model_text: '',
                models: []
            }
        },
        computed: {
            bucket_token() {
                let bucket_token = ''
                if (this.$store.state.default_bucket_tokens.org) {
                    bucket_token = this.$store.state.default_bucket_tokens.org
                } else {
                    bucket_token = this.$store.state.default_bucket_tokens.user
                }
                return bucket_token
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            },
            local_install() {
                return this.$store.state.local_install
            },
            lang() {
                return this.$store.state.lang
            }
        },
        methods: {
            handle_orientation(e, index1=0, index2=0) {
                this.getModels()
                this.layout_combinations = this.layouts[e].combinations
                this.calc_scale(this.layouts[e].width, this.layouts[e].height)

                this.handle_layout(index1, index2)
            },
            handle_layout(e, e2=0, key1="", key2="") {
                if (!key1|| !key2) {

                    this.formValidate.key1 = this.layouts[this.formValidate.orientation].combinations[e].key
                    this.formValidate.key2 = this.layouts[this.formValidate.orientation].combinations[e].layouts[e2].key

                } else {
                    this.formValidate.key1 = key1
                    this.formValidate.key2 = key2
                }

                this.$nextTick(function() {
                    let eles = document.getElementsByClassName('cells-info'),
                        len = eles.length;

                    for (var i = 0; i < len; i++) {
                        eles[i].setAttribute('class', 'cells-info')
                    }
                    eles[e].setAttribute('class', 'cells-info currentTarget')
                })
                this.formValidate.combination = e
                this.layout_layouts = this.layout_combinations[e].layouts
                this.handle_cells(e2)
            },
            handle_cells(e) {
                this.$nextTick(function() {
                    let eles = document.getElementsByClassName('currentTarget')[0].getElementsByClassName('cells-item'),
                        len = eles.length;

                    for (var i = 0; i < len; i++) {
                        eles[i].setAttribute('class', 'cells-item')
                    }
                    eles[e].setAttribute('class', 'cells-item target')
                })
                this.formValidate.cell = e
                this.layout_cells = this.layout_layouts[e].cells
                this.showContentList = false
                this.apply.canvas.cells = this.layout_layouts[e].cells
                this.apply.canvas.cells.forEach((item, index) => {
                    if (!item.conIndex) {
                        // item.conIndex = 0
                        this.$set(this.apply.canvas.cells[index], 'conIndex', 0)
                    }
                })
            },
            add () {
                if (this.percent >= 100) {
                    return false;
                }
                this.percent += 10;
                this.scale = this.percent / 100
            },
            minus () {
                if (this.percent <= 20) {
                    return false;
                }
                this.percent -= 10;
                this.scale = this.percent / 100
            },
            handleResizeProgram() {
                ResizeProgram({
                    lang: this.lang,
                    models: this.models,
                    programInfo: this.formValidate,
                    origionTmpl: util.deepClone(this.layouts),
                    handleChanged: (data) => {
                        //根据key查询
                        this.model = data.model
                        this.model_text = data.model_text
                        this.layouts = util.deepClone(data.layouts)
                        let combinations = this.layouts[this.formValidate.orientation].combinations,
                            index1 = 0,
                            index2 = 0,
                            len = combinations.length,
                            layouts = [];
                        for (let i = 0; i < len; i++) {
                            if (combinations[i].key === this.formValidate.key1) {
                                index1 = i
                                layouts = combinations[i].layouts
                                break
                            }
                        }
                        let len2 = layouts.length;
                        for (var j = 0; j < len2; j++) {
                            if (layouts[j].key === this.formValidate.key2) {
                                index2 = j
                                break
                            }
                        }
                        this.handle_orientation(this.formValidate.orientation, index1, index2)
                    }
                })
            },
            handleAudio() {
                this.audioModalShow = true
            },
            handleAudioConfirm(params) {
                this.audio = params
                this.ttsInfo = null
            },
            handleAudioCancel() {
                this.audioModalShow = false
            },
            handleRemoveAudio() {
                this.audio = null
            },
            handleRemoveTTS() {
                this.ttsInfo = null
            },
            handleTTS() {
                this.ttsModalShow = true
            },
            handleTtsCancel() {
                this.ttsModalShow = false
            },
            handleTtsConfirm(params) {
                this.ttsInfo = params
                this.audio = null
            },
            handleBackground() {
                this.showContentList = false
            },
            handleContentList(index) {
                this.showContentList = true
                this.cellIndex = index
                this.apply.canvas.cells = this.layout_cells
                if (!this.apply.canvas.cells[index].materials || this.apply.canvas.cells[index].materials.length === 0) {
                    this.handleContent()
                }
            },
            handleContent() {
                this.cellWidth = this.apply.canvas.cells[this.cellIndex].width
                this.cellHeight = this.apply.canvas.cells[this.cellIndex].height
                this.contentModalShow = true
            },
            handleContentConfirm(result) {
                if (!this.apply.canvas.cells[this.cellIndex].materials) {
                    this.$set(this.apply.canvas.cells[this.cellIndex], 'materials', result)
                } else {
                    if (this.replace) {
                        //替换
                        let conIndex = this.apply.canvas.cells[this.cellIndex].conIndex
                        this.apply.canvas.cells[this.cellIndex].materials.splice(this.replaceIndex, 1, ...result)
                        if (conIndex > this.replaceIndex) {
                            this.apply.canvas.cells[this.cellIndex].conIndex += result.length - 1;
                        }
                        this.replace = false
                        return
                    }
                    //末尾增加
                    result.map((item)=>{
                        this.apply.canvas.cells[this.cellIndex].materials.push(item)
                    })
                }
            },
            handleContentCancel() {
                this.contentModalShow = false
            },
            handleRemoveContent(index) {
                let conIndex = this.apply.canvas.cells[this.cellIndex].conIndex
                if (conIndex >= index && conIndex > 0) {
                    this.apply.canvas.cells[this.cellIndex].conIndex -= 1
                }
                this.apply.canvas.cells[this.cellIndex].materials.splice(index, 1)
            },
            handleReplaceContent(index) {
                // let conIndex = this.apply.canvas.cells[this.cellIndex].conIndex
                this.replace = true
                this.replaceIndex = index
                this.handleContent()
            },
            handleAdd() {
                //保存原始数据，用于操作失败后的回填
                this.originApply = util.deepClone(this.apply)
                let cells = util.deepClone(this.apply.canvas.cells)
                cells.forEach((item) => {
                    //删除前端操作标记用的数据
                    delete item.conIndex
                })
                this.apply.canvas.cells = cells

                this.apply.canvas.model = this.model
                this.apply.canvas.key1 = this.formValidate.key1
                this.apply.canvas.key2 = this.formValidate.key2
                this.apply.name = this.formValidate.name
                this.apply.mode = this.formValidate.orientation
                this.apply.bucket_token = this.bucket_token
                this.apply.canvas.width = this.layouts[this.formValidate.orientation].width
                this.apply.canvas.height = this.layouts[this.formValidate.orientation].height

                if (this.audio) {
                    this.apply.background.materials = [this.audio]
                } else if (this.ttsInfo) {
                    this.apply.background.materials = [this.ttsInfo]
                } else {
                    this.apply.background.materials = []
                }
                return this.$api.post('programs', this.apply).then(({ rowid }) => {
                    return this.getImg().then(base64 => {
                        return this.$api.post(`programs/${rowid}/cover_image`, { file_base64: base64 }).then(() => {
                            this.$Message.success(this.$t('message.success'));
                            this.$router.replace({ name: 'programs' })
                        })
                    })

                })
            },
            handleSave() {
                if (this.loadingSave) return
                this.loadingSave = true
                if (this.formValidate.name === '') {
                    return this.$Message.error(this.$t('pages.program.inputName'))
                }

                if (this.status === 'add') {
                    this.handleAdd().finally(() => {
                        this.apply = this.originApply
                        this.loadingSave = false
                    })

                } else if (this.status === 'edit') {
                    //保存原始数据，用于操作失败后的回填
                    this.originApply = util.deepClone(this.apply)
                    let cells = util.deepClone(this.apply.canvas.cells)
                    cells.forEach((item) => {
                        //删除前端操作标记用的数据
                        delete item.conIndex
                    })
                    this.apply.canvas.cells = cells
                    this.apply.mode = this.formValidate.orientation
                    this.apply.canvas.key1 = this.formValidate.key1
                    this.apply.canvas.key2 = this.formValidate.key2
                    this.apply.canvas.model = this.model
                    this.apply.canvas.width = this.layouts[this.formValidate.orientation].width
                    this.apply.canvas.height = this.layouts[this.formValidate.orientation].height

                    if (this.audio) {
                        this.apply.background.materials = [this.audio]
                    } else if (this.ttsInfo) {
                        this.apply.background.materials = [this.ttsInfo]
                    } else {
                        this.apply.background.materials = []
                    }
                    this.$api.put(`programs/${this.rowid}`, this.apply).then(() => {
                        this.$Message.success(this.$t('message.success'));
                        this.getImg().then(base64 => {
                            this.$api.post(`programs/${this.rowid}/cover_image`, { file_base64: base64 }).then(() => {
                                this.$router.replace({ name: 'programs' })
                            }).finally(() => {
                                this.loadingSave = false
                            })
                        })
                    }).finally(() => {
                        this.apply = this.originApply
                        this.loadingSave = false
                    })
                }
            },
            handleSaveAs() {
                let origionName = this.formValidate.name
                this.$Modal.confirm({
                    title: this.$t('pages.program.saveAs'),
                    loading: true,
                    render: (h) => {
                        return h('Input', {
                            props: {
                                value: this.formValidate.name,
                                autofocus: true,
                                placeholder: this.$t('pages.program.inputName')
                            },
                            on: {
                                input: (val) => {
                                    this.formValidate.name = val;
                                }
                            }
                        })
                    },
                    onOk: () => {
                        if (this.formValidate.name === '') {
                            return this.$Message.error(this.$t('pages.program.inputName'))
                        }
                        if (this.loadingSaveAs) return
                        this.loadingSaveAs = true
                        this.handleAdd().then(() => {
                            this.formValidate.name = origionName
                        }).finally(() => {
                            this.$Modal.remove()
                            this.apply = this.originApply
                            this.loadingSaveAs = false
                        })
                    },
                    onCancel: () => {
                        this.formValidate.name = origionName
                        this.apply.name = origionName
                    }
                })
            },
            getData() {
                this.$api.get(`programs/${this.rowid}`).then(({ data }) => {
                    //拼接存储数据
                    this.model = data.program.canvas.model
                    this.formValidate.key1 = data.program.canvas.key1
                    this.formValidate.key2 = data.program.canvas.key2
                    this.formValidate.name = data.name
                    this.apply.name = data.name
                    this.apply.mode = data.mode
                    this.apply.background = data.program.background
                    this.apply.canvas = data.program.canvas

                    //显示背景音乐
                    //显示tts...
                    let bkgMaterials = data.program.background.materials
                    if (bkgMaterials.length) {
                        if (bkgMaterials[0].media_type === 'tts') {
                            this.ttsInfo = bkgMaterials[0]
                        } else {
                            this.audio = bkgMaterials[0]
                        }
                    }

                    this.getLayouts().then(() => {
                        //显示布局
                        this.formValidate.orientation = data.mode
                        this.formValidate.name = data.name
                        //根据key查询
                        let combinations = this.layouts[data.mode].combinations,
                            index1 = null,
                            index2 = null,
                            len = combinations.length,
                            layouts = [];
                        for (let i = 0; i < len; i++) {
                            if (combinations[i].key === this.formValidate.key1) {
                                index1 = i
                                layouts = combinations[i].layouts
                                break
                            }
                        }
                        let len2 = layouts.length;
                        for (var j = 0; j < len2; j++) {
                            if (layouts[j].key === this.formValidate.key2) {
                                index2 = j
                                break
                            }
                        }
                        //处理立即投放时，后端生成的节目尺寸数据与layouts中不一致时。
                        if (data.program.canvas.width !== this.layouts[this.formValidate.orientation].width || data.program.canvas.height !== this.layouts[this.formValidate.orientation.height] && data.program.canvas.cells.length === 1) {
                            let scale_x = this.layouts[this.formValidate.orientation].width / data.program.canvas.width,
                                scale_y =  this.layouts[this.formValidate.orientation].height / data.program.canvas.height,
                                scale = Math.min(scale_x, scale_y);
                                data.program.canvas.width = this.layouts[this.formValidate.orientation].width
                                data.program.canvas.height = this.layouts[this.formValidate.orientation].height
                                data.program.canvas.cells.forEach((cell) => {
                                    cell.width = this.layouts[this.formValidate.orientation].width
                                    cell.height = this.layouts[this.formValidate.orientation].height
                                    cell.x *= scale
                                    cell.y *= scale
                                    cell.materials.forEach((material) => {
                                        material.width *= scale
                                        material.height *= scale
                                        material.x = cell.width / 2 - material.width / 2
                                        material.y = cell.height / 2 - material.height / 2
                                    })
                                })
                        }
                        this.layouts[this.formValidate.orientation].combinations[
                         index1].layouts[index2].cells = data.program.canvas.cells

                        this.handle_orientation(this.formValidate.orientation, index1, index2)
                    })
                })
            },
            calc_scale(width, height) {
                //计算缩放scale
                const ele = document.querySelector('.editor'),
                      canvas_width = ele.offsetWidth,
                      canvas_height = ele.offsetHeight,
                      scale_x = (Math.floor((canvas_width / width) * 10) / 10).toFixed(1),
                      scale_y = (Math.floor((canvas_height / height )* 10) / 10).toFixed(1),
                      scale = Math.min(scale_x, scale_y);

                if (scale <= 0.2) {
                    this.scale = 0.2
                    this.percent = 20
                } else if (scale >= 1) {
                    this.scale = 1
                    this.percent = 100
                } else {
                    this.scale = scale
                    this.percent = scale * 100
                }
            },
            getLayouts() {
                let  params = {
                    model: this.model
                }
                return this.$api.get('programs/layouts', { params }).then(({ layouts }) => {
                    this.layouts = layouts
                    if (this.status === 'add') {
                        this.formValidate.orientation = Object.keys(layouts)[0] || ''
                        this.handle_orientation(this.formValidate.orientation)
                    }
                })
            },
            handleRename(name) {
                this.formValidate.name = name
                if (this.status === 'edit') {
                    if (name === '') {
                        return this.$Message.error(this.$t('pages.program.inputName'))
                    }
                    let params = {
                        name: name
                    }
                    this.$api.put(`programs/${this.rowid}/name`, params).then(() => {
                        this.$Message.success(this.$t('common.success'))
                        this.apply.name = name
                    })
                }
            },
            handleHidden() {
                this.hidden = !this.hidden
                setTimeout(() => {
                    this.$nextTick(() => {
                        const width = this.layouts[this.formValidate.orientation].width,
                              height = this.layouts[this.formValidate.orientation].height;

                        this.calc_scale(width, height)
                    })

                }, 300)
            },
            getImg() {
                const convertToImage = (container, options = {}) => {
                    // 设置放大倍数
                    const scale = 2

                    // 传入节点原始宽高
                    const width = container.offsetWidth;
                    const height = container.offsetHeight;

                    // html2canvas配置项
                    const ops = {
                        imageTimeout: 0,
                        scale,
                        width,
                        height,
                        useCORS: true,
                        allowTaint: false,
                        scrollY: 0,
                        scrollX: 0,
                        ignoreElements: (element) => {
                            if (element.classList.contains('module-info') || element.classList.contains('add-icon')) {
                                return true
                            }
                        },
                        ...options
                    };
                    return html2canvas(container, ops).then(canvas => {
                        // 返回图片的二进制数据
                        return canvas.toDataURL("image/png")
                    });
                }
                this.scale = 0.5
                this.percent = 50
                // 调用函数，取到截图的二进制数据，对图片进行处理（保存本地、展示等）
                return this.$nextTick().then(() => {
                    return convertToImage(document.getElementById('canvas'));
                })
            },
            handleMousewheel(e, item) {
                //detail兼容firefox
                const eventDelta = e.wheelDelta || -e.deltaY * 40 || e.detail;
                let zoomSize = parseInt((eventDelta / 120) * 100) / 100,
                    index = item.conIndex;

                //限制滚动量
                if (Math.abs(zoomSize) > 0.1) {
                    if (zoomSize > 0) {
                        zoomSize = 0.1
                    } else {
                        zoomSize = -0.1
                    }
                }
                //限制条件
                //原图尺寸小于区域时
                if (item.height > item.materials[index].resolution[1] || item.width > item.materials[index].resolution[0]) {
                    //最大限制：大等于原图三倍后，禁止放大
                    if (item.materials[index].width / item.materials[index].resolution[0] >= 3 || item.materials[index].height / item.materials[index].resolution[1] >= 3) {
                        if (zoomSize > 0) {
                            zoomSize = 0
                        }
                    }
                    //最小限制：小等于原图的0.5倍后，禁止缩小
                    if (item.materials[index].width / item.materials[index].resolution[0] <= 0.5 || item.materials[index].height / item.materials[index].resolution[1] <= 0.5) {
                        if (zoomSize < 0) {
                            zoomSize = 0
                        }
                    }
                } else {//原图尺寸大于区域时
                    //最大限制：原图长宽与区域长宽之比最小值大于3倍，禁止放大
                    if (Math.min(item.materials[index].width / item.width, item.materials[index].height / item.height) >= 3) {
                        if (zoomSize > 0) {
                            zoomSize = 0
                        }
                    }
                    //最小限制：原图长宽与区域长宽之比最大值小于0.5倍后，禁止缩小
                    if (Math.max(item.materials[index].width / item.width, item.materials[index].height / item.height) <= 0.5) {
                        if (zoomSize < 0) {
                            zoomSize = 0
                        }
                    }
                }
                let diff_w = parseInt(item.materials[index].width * zoomSize),
                    diff_h = parseInt(item.materials[index].height * zoomSize),
                    width = item.materials[index].width,
                    height = item.materials[index].height,
                    x = item.materials[index].x,
                    y = item.materials[index].y,
                    cell_width = item.width,
                    cell_height = item.height;

                if (x + width + diff_w / 2 <= this.offset || x - diff_w / 2 >= cell_width - this.offset || y + height + diff_h / 2 <= this.offset || y - diff_h / 2 >= cell_height - this.offset) {
                    x = cell_width / 2 - width / 2
                    y = cell_height / 2 - height / 2
                } else {
                    x -= diff_w / 2
                    y -= diff_h / 2
                }
                item.materials[index].height += diff_h
                item.materials[index].width += diff_w

                item.materials[index].y = y
                item.materials[index].x = x
            },
            greet(params) {
                let index = this.apply.canvas.cells[params.index].conIndex;
                let y = this.apply.canvas.cells[params.index].materials[index].y + params.diff_y / this.scale,
                    x = this.apply.canvas.cells[params.index].materials[index].x + params.diff_x / this.scale,
                    width = this.apply.canvas.cells[params.index].materials[index].width,
                    height = this.apply.canvas.cells[params.index].materials[index].height,
                    cell_width = this.apply.canvas.cells[params.index].width,
                    cell_height = this.apply.canvas.cells[params.index].height;
                if (x + width <= this.offset || x >= cell_width - this.offset || y + height <= this.offset || y >= cell_height - this.offset) {
                    x = cell_width / 2 - width / 2
                    y = cell_height / 2 -height / 2
                }
                this.apply.canvas.cells[params.index].materials[index].y = y
                this.apply.canvas.cells[params.index].materials[index].x = x
            },
            goTo() {
                this.$router.replace({ name: 'personSpaces' })

            },
            handleConIndex(index) {
                this.layout_cells[this.cellIndex].conIndex = index
            },
            handleResize() {
                let width = this.layouts[this.formValidate.orientation].width,
                    height = this.layouts[this.formValidate.orientation].height;
                this.calc_scale(width, height)
            },
            getModels() {
                let params = {
                    orientation: this.formValidate.orientation
                }
                this.$api.get('programs/layout_models', { params }).then(({ layouts }) => {
                    this.models = layouts
                    this.models.map(model => {
                        if (model.model === this.model) {
                            this.model_text = model.model_orientation_text[this.lang]?model.model_orientation_text[this.lang]:model.model_orientation_text._
                        }
                    })
                })
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize, false)
        },
        mounted() {
            this.status = this.$route.query.status
            this.rowid = this.$route.query.rowid
            // this.getModels()
            this.$nextTick(function() {
                // 随窗口缩放
                window.addEventListener('resize', this.handleResize, false)
                if (this.status === 'edit') {
                    this.getData()
                } else {
                    this.getLayouts().then(() => {
                        let t = new Date(),
                            y = t.getFullYear(),
                            m = String(t.getMonth() + 1).padStart("2", "0"),
                            d = String(t.getDate()).padStart("2", "0"),
                            h = String(t.getHours()).padStart("2", "0"),
                            mi = String(t.getMinutes()).padStart("2", "0"),
                            s = String(t.getSeconds()).padStart("2", "0");

                        this.formValidate.name = `${this.$t('pages.program.program')}${y}${m}${d}${h}${mi}${s}`
                    })
                }
            })
        }
    }
</script>
<style scoped lang="less">
@import '~less/color';
.program-page {
    overflow: hidden;
    height: 100%;
    .program-header {
        padding: 20px  11px 0;
        border-bottom: 2px solid #eef2f8;
        .program-title {
            display: inline-block;
            margin-right: 21px;
            padding-left: 22px;
            font-size: 29px;
            font-weight: 700;
            color: #092c4c;
        }
        .program-name {
            display: inline-block;
            font-size: 18px;
            color: #afb4ba;
        }
        .name-wrap {
            display: inline-block;
            margin-bottom: 5px;
            width: 210px;
            font-size: 18px;
            color: #afb4ba;
            vertical-align: bottom;
            .name {
                display: inline-block;
                position: relative;
                max-width: calc(100% - 24px);
                line-height: 21px;
                vertical-align: middle;
            }
            .icon {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .editor-body {
        position: relative;
        height: calc(100vh - 65px);
        .side-bar {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0px;
            width: 357px;
            height: 100%;
            transition: width .3s ease-in;
            .side-bar-item {
                overflow-x: hidden;
                display: inline-block;
                .cells-info {
                    width: 278px;
                }
            }
            .side-left-item {
                padding-top: 33px;
                width: 58px;
                height: 100%;
                border-right: 2px solid #eef2f8;
                vertical-align: top;
                .block {
                    position: relative;
                    &::before {
                        content: '\200B';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 3px;
                        height: 27px;
                        background: @primary-color;
                    }
                    .img-wrap {
                        position: relative;
                        margin: 0 auto;
                        width: 17px;
                        height: 17px;
                    }
                    span  {
                        display: inline-block;
                        width: 100%;
                        font-size: 12px;
                        color: @primary-color;
                        text-align: center;
                    }
                }
            }
            .side-right-item {
                overflow-y: auto;
                overflow-x: hidden;
                position: absolute;
                left: 58px;
                top: 0;
                width: 299px;
                height: 100%;
                padding: 0 0 0 12px;
                .currentTarget {
                    .target {
                        .cells-wrapper {
                            border: 1px solid @primary-color;
                        }
                    }
                }
                .cells-title {
                    margin: 16px 0 12px 0;
                    font-size: 16px;
                    line-height: 29px;
                }
                .cells-item {
                    cursor: pointer;
                    display: inline-block;
                    margin-right: 4px;
                    width: 135px;
                    .cells-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 98px;
                        background: #f8fbff;
                        .cells-con {
                            background: #fff;
                        }
                    }
                    .text {
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                        font-size: 14px;
                        line-height: 29px;
                        color: #afb4ba;
                    }
                }

            }
        }
        .side-bar-hidden {
            width: 58px;
        }
        .editor {
            position: absolute;
            top: 0;
            left: 357px;
            right: 280px;
            bottom: 0;
            background: #F8FBFF;
            height: 100%;
            transition: all .3s ease-in;
            .editor-container-wrap {
                overflow: auto;
                width: 100%;
                height: 100%;
                .editor-container {
                    margin: 0 auto;
                    position: relative;
                    /*transform-origin: center center;*/
                    background: #eef2f8;
                    .cells {
                        overflow: hidden;
                        position: absolute;
                        box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
                        .module-info {
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            color: #afb4ba;
                        }
                    }
                }
                .model-text {
                    margin-top: 10px;
                    color: #afb4ba;
                    text-align: center;
                }
            }
            .controls {
                position: absolute;
                bottom: 10px;
                right: 0px;
                span {
                    vertical-align: middle;
                }
            }
        }
        .editor-hidden {
            left: 58px;
        }
        .editor-panel-wrap {
            overflow-y: auto;
            overflow-x: hidden;
            position: absolute;
            top: 0;
            right: 0;
            width: 280px;
            height: 100%;
            .background-setting {
                .edit-size-wrap {
                    margin: 0 auto;
                    padding-top: 8px;
                    width: 257px;
                    font-size: 14px;
                    .size-action-row {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #afb4ba;
                    }
                    .size-display-row {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 8px 0 28px;
                        .size-width, .size-height {
                            padding-left: 17px;
                            width: 123px;
                            height: 41px;
                            background: #f1f2f4;
                            border-radius: 4px;
                            line-height: 41px;
                        }
                    }
                }
                .background-title {
                    height: 50px;
                    border-bottom: 2px solid #eef2f8;
                    text-align: center;
                    font-size: 16px;
                    line-height: 48px;
                }
                .audio-type {
                    height: 46px;
                    padding-left: 12px;
                    font-size: 14px;
                    line-height: 46px;
                    color: #afb4ba;
                }
                .audio-wrap, .tts-wrap {
                    position: relative;
                    margin: 0 auto;
                    width: 257px;
                    height: 161px;
                    background: #ececec;
                    border-radius: 4px;
                    .img-wrap {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        .name {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            background: #666666;
                            font-size: 16px;
                            color: #ececec;
                            text-align: center;
                        }
                    }
                    .icon-remove {
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        color: #dcdcdc;
                    }
                    span {
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        border-radius: 4px;
                        background: rgba(0,0,0,.08);
                    }
                }
            }
            .content-setting {
                .content-title {
                    margin: 33px auto 25px;
                    width: 250px;
                    height: 41px;
                    border: 1px solid #cccccc;
                    background: #f1f2f4;
                    font-size: 16px;
                    line-height: 41px;
                    text-align: center;
                }
            }
            .content-wrap-move {
                cursor: move;
            }
            .content-wrap {
                position: relative;
                margin: 0 0 39px 11.5px;
                width: 268.5px;
                height: 160px;
                .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                .img-wrap {
                    position: relative;
                    display: inline-block;
                    width: 160px;
                    height: 160px;
                    background: #fff;
                    border: 1px solid #f0f0f0;
                    border-radius: 4px;
                    .name {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        background: #666666;
                        font-size: 16px;
                        color: #ececec;
                        text-align: center;
                    }
                    .icon-replace-wrap {
                        position: absolute;
                        top: 9px;
                        right: 42px;
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        background: #999999;
                    }
                    .icon-remove-wrap {
                        position: absolute;
                        top: 9px;
                        right: 9px;
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        background: #999999;
                    }
                    .icon-replace-wrap, .icon-remove-wrap {
                        .icon {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: #fff;
                        }
                    }
                }
                .info-wrap {
                    position: relative;
                    display: inline-block;
                    padding-left: 10px;
                    width: 108.5px;
                    height: 160px;
                    .times, .duration {
                        position: absolute;
                        bottom: 0;
                        left: 10px;
                        width: 98.5px;
                        .text {
                            margin-bottom: 8px;
                            font-size: 16px;
                        }
                        .input {
                            margin-right: 7px;
                            width: 58px;
                            background: #f1f2f4;
                            border: 1px solid #cccccc;
                            vertical-align: middle;
                        }
                        span {
                            vertical-align: middle;
                            font-size: 16px;
                        }
                    }
                }
            }
            .content-add {
                margin-left: 11.5px;
                width: 160px;
                height: 100px;
                background: #ececec;
                border-radius: 4px;
            }
        }
        .editor-content-block {
            background: #f8fbff;
        }
    }
}

</style>